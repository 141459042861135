import { Injectable } from '@angular/core';
import { type Observable, of } from 'rxjs';
import { type ODataArrayResult } from '@models/dto/odata-result';
import { FoBaseClientApiService } from '@services/finops/fo-base.service';
import { StringValidator } from '@app/shared/utils/validators/string.validator';
import { D365Entities } from '@models/dto/D365Entities.enum';
import { FoEmployeesV2DtoFields, type ResquestableFoEmployeesV2Dto, requestableFoCustomerV2DtoFields } from '@app/core/models/dto/fo/fo.employeesV2.dto';

@Injectable({
  providedIn: 'root'
})
export class FoEmployeeService extends FoBaseClientApiService<ResquestableFoEmployeesV2Dto> {
  protected override getMainEntityName(): string {
    return D365Entities.EmployeeV2;
  }
  /**
   * Retrieves some employees based on the provided select fields, filter parameters, order by, expand, and cross company flag.
   *
   * @param {string[]} selectFields - The fields to select from the employees.
   * @param {unknown} filterParams - The filter parameters to apply to the employees.
   * @param {string[]} [orderBy] - The fields to order the employees by.
   * @param {string[]} [expand] - The fields to expand in the employees.
   * @param {boolean} [crossCompany=false] - Whether to include employees from all companies.
   * @return {Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>>} An observable that emits an ODataResult containing the requested employees.
   */
  getSomeEmployeesV2$ (selectFields: string[], filterParams: unknown, orderBy?: string[], expand?: string[], crossCompany: boolean = false): Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>> {
    return this.foODataService.getSomeWithFilterParams<ResquestableFoEmployeesV2Dto>(this.getMainEntityName(), selectFields, filterParams, orderBy, expand, crossCompany);
    }
  /**
   * Get some employees by email
   * @param email
   * @returns Observable<ODataResult<ResquestableFoEmployeesV2Dto>>
   */
  getSomeEmployeesByEmail$ (email: string): Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>> {
    if (StringValidator.isNullOrEmptyString(email)) return of(this.defaultOdataResult);

    const filterParams = { PrimaryContactEmail: `${email}` };

    try {
      return this.getSomeEmployeesV2$(
        requestableFoCustomerV2DtoFields
        , filterParams);
    } catch (error) {
      console.error('Error getting employees by email:', error);
      throw error;
    }
  }

  /**
   * Retrieves some employees based on the provided email and company.
   *
   * @param {string} email - The email of the employees to retrieve.
   * @param {string} company - The company associated with the employees.
   * @return {Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>>} An observable that emits an ODataResult containing the requested employees.
   */
  getSomeEmployeesByEmailAndCompany$ (email: string, company: string): Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>> {
    if (StringValidator.isNullOrEmptyString(email)) return of(this.defaultOdataResult);

    const filterParams = { PrimaryContactEmail: `${email}`, EmploymentLegalEntityId: `${company}` };

    try {
      return this.getSomeEmployeesV2$(
        requestableFoCustomerV2DtoFields
        , filterParams);
    } catch (error) {
      console.error('Error getting employees by email and company:', error);
      throw error;
    }
  }

  /**
   * Retrieves some data area IDs based on the provided email.
   *
   * @param {string} email - The email of the employees to retrieve.
   * @return {Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>>} An observable that emits an ODataResult containing the requested data area IDs.
   */
  getSomeDataAreaIdByEmail$ (email: string): Observable<ODataArrayResult<ResquestableFoEmployeesV2Dto>> {
    if (StringValidator.isNullOrEmptyString(email)) return of(this.defaultOdataResult);

    const filterParams = { PrimaryContactEmail: `${email}` };

    try {
      return this.getSomeEmployeesV2$(
        [
          FoEmployeesV2DtoFields.EmploymentLegalEntityId
        ]
        , filterParams);
    } catch (error) {
      console.error('Error getting DataAreaId by email:', error);
      throw error;
    }
  }
}
