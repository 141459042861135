export interface FoEmployeesV2Dto {
  '@odata.etag': string
  PersonnelNumber: string
  EmploymentLegalEntityId: string
  EmploymentId: string
  OfficeLocationId: string
  EmployeeDetailsEffective: string
  AddressState: string
  AddressBooks: string
  AddressCity: string
  ProfessionalSuffix: string
  OriginalHireDateTime: string
  EmployeeDetailsExpiration: string
  AddressZipCode: string
  MilitaryServiceStartDate: string
  NationalityCountryRegion: string
  PrimaryContactURLDescription: string
  PersonBirthCity: string
  EmploymentStartDate: string
  PrimaryContactPhoneExtension: string
  PrimaryContactEmailIsPrivate: string
  PrimaryContactURLIsPrivate: string
  FatherBirthCountryRegion: string
  AddressCounty: string
  PrimaryContactEmailIsIM: string
  PrimaryContactFacebookIsPrivate: string
  AddressLocationId: string
  PersonBirthCountryRegion: string
  EmploymentNoticeWorkerQuantity: number
  LastName: string
  RelocationReimbursement: number
  IsFulltimeStudent: string
  PrimaryContactURL: string
  PrimaryContactPhonePurpose: string
  PrimaryContactFax: string
  AddressStreet: string
  PersonalSuffix: string
  ExpatriateRulingValidTo: string
  PrimaryContactTwitterIsPrivate: string
  PrimaryContactPhoneIsMobile: string
  AddressValidTo: string
  NameSequenceDisplayAs: string
  DimensionDisplayValue: string
  TitleId: string
  LanguageId: string
  PrimaryContactPhoneDescription: string
  AddressCountryRegionId: string
  AddressNameDescription: string
  PersonDetailsValidFrom: string
  PrimaryContactFaxIsPrivate: string
  PrimaryContactLinkedInPurpose: string
  TerminationReasonCodeId: string
  MilitaryServiceEndDate: string
  PrimaryContactTwitterPurpose: string
  ProbationPeriod: string
  LastNamePrefix: string
  LastDateWorked: string
  BirthDate: string
  PrimaryContactFacebookDescription: string
  WorksFromHome: string
  SummaryValidFrom: string
  PrimaryContactFaxExtension: string
  AddressCountryRegionISOCode: string
  PartyType: string
  VeteranStatusId: string
  RegulatoryEstablishmentId: string
  PrimaryContactTwitterDescription: string
  TerminationDate: string
  EmploymentEndDate: string
  PersonDetailsValidTo: string
  PrimaryContactLinkedInDescription: string
  CitizenshipCountryRegion: string
  MiddleName: string
  PrimaryContactEmailPurpose: string
  EmploymentNoticeEmployerQuantity: number
  AddressValidFrom: string
  PrimaryContactURLPurpose: string
  AddressDistrictName: string
  IsDisabled: string
  Gender: string
  AddressLocationRoles: string
  ExpatriateRulingValidFrom: string
  SummaryValidTo: string
  PartyNumber: string
  PrimaryAddressLocation: number
  AllowRehire: string
  Name: string
  PrimaryContactFaxDescription: string
  PrimaryContactLinkedIn: string
  NumberOfDependents: number
  PersonalTitle: string
  PrimaryContactFacebook: string
  NameAlias: string
  DeceasedDate: string
  PrimaryContactPhone: string
  Education: string
  SeniorityDate: string
  PrimaryContactEmailDescription: string
  StartDate: string
  MotherBirthCountryRegion: string
  OfficeLocation: string
  EmploymentNoticeEmployerUnit: string
  PrimaryContactEmail: string
  PhoneticMiddleName: string
  IsExpatriateRulingApplicable: string
  PhoneticLastName: string
  PrimaryContactTwitter: string
  EmploymentDetailsExpiration: string
  PrimaryContactFacebookPurpose: string
  AnniversaryDateTime: string
  PrimaryContactPhoneIsPrivate: string
  FirstName: string
  ProfessionalTitle: string
  PrimaryContactLinkedInIsPrivate: string
  NativeLanguageId: string
  PrimaryContactFaxPurpose: string
  KnownAs: string
  AdjustedWorkerStartDate: string
  EmploymentNoticeWorkerUnit: string
  DisabledVerificationDate: string
  MaritalStatus: string
  EmploymentDetailsEffective: string
  IsDisabledVeteran: string
  EthnicOriginId: string
  PhoneticFirstName: string
  ElectronicLocationId: string
  PensionStart: string
  PensionEnrollment: string
  CalendarDataAreaId: string
  CalendarId: string
  SPLExternalPersonnelNumber: string
  SPLRecId: number
  SPLRecVersion: number
  SPLEmployeeType: string
  SPLModifiedDateTime: string
  SPLCreatedDateTime: string
};
export enum FoEmployeesV2DtoFields {
  ODataETag = '@odata.etag',
  PersonnelNumber = 'PersonnelNumber',
  EmploymentLegalEntityId = 'EmploymentLegalEntityId',
  EmploymentId = 'EmploymentId',
  OfficeLocationId = 'OfficeLocationId',
  EmployeeDetailsEffective = 'EmployeeDetailsEffective',
  AddressState = 'AddressState',
  AddressBooks = 'AddressBooks',
  AddressCity = 'AddressCity',
  ProfessionalSuffix = 'ProfessionalSuffix',
  OriginalHireDateTime = 'OriginalHireDateTime',
  EmployeeDetailsExpiration = 'EmployeeDetailsExpiration',
  AddressZipCode = 'AddressZipCode',
  MilitaryServiceStartDate = 'MilitaryServiceStartDate',
  NationalityCountryRegion = 'NationalityCountryRegion',
  PrimaryContactURLDescription = 'PrimaryContactURLDescription',
  PersonBirthCity = 'PersonBirthCity',
  EmploymentStartDate = 'EmploymentStartDate',
  PrimaryContactPhoneExtension = 'PrimaryContactPhoneExtension',
  PrimaryContactEmailIsPrivate = 'PrimaryContactEmailIsPrivate',
  PrimaryContactURLIsPrivate = 'PrimaryContactURLIsPrivate',
  FatherBirthCountryRegion = 'FatherBirthCountryRegion',
  AddressCounty = 'AddressCounty',
  PrimaryContactEmailIsIM = 'PrimaryContactEmailIsIM',
  PrimaryContactFacebookIsPrivate = 'PrimaryContactFacebookIsPrivate',
  AddressLocationId = 'AddressLocationId',
  PersonBirthCountryRegion = 'PersonBirthCountryRegion',
  EmploymentNoticeWorkerQuantity = 'EmploymentNoticeWorkerQuantity',
  LastName = 'LastName',
  RelocationReimbursement = 'RelocationReimbursement',
  IsFulltimeStudent = 'IsFulltimeStudent',
  PrimaryContactURL = 'PrimaryContactURL',
  PrimaryContactPhonePurpose = 'PrimaryContactPhonePurpose',
  PrimaryContactFax = 'PrimaryContactFax',
  AddressStreet = 'AddressStreet',
  PersonalSuffix = 'PersonalSuffix',
  ExpatriateRulingValidTo = 'ExpatriateRulingValidTo',
  PrimaryContactTwitterIsPrivate = 'PrimaryContactTwitterIsPrivate',
  PrimaryContactPhoneIsMobile = 'PrimaryContactPhoneIsMobile',
  AddressValidTo = 'AddressValidTo',
  NameSequenceDisplayAs = 'NameSequenceDisplayAs',
  DimensionDisplayValue = 'DimensionDisplayValue',
  TitleId = 'TitleId',
  LanguageId = 'LanguageId',
  PrimaryContactPhoneDescription = 'PrimaryContactPhoneDescription',
  AddressCountryRegionId = 'AddressCountryRegionId',
  AddressNameDescription = 'AddressNameDescription',
  PersonDetailsValidFrom = 'PersonDetailsValidFrom',
  PrimaryContactFaxIsPrivate = 'PrimaryContactFaxIsPrivate',
  PrimaryContactLinkedInPurpose = 'PrimaryContactLinkedInPurpose',
  TerminationReasonCodeId = 'TerminationReasonCodeId',
  MilitaryServiceEndDate = 'MilitaryServiceEndDate',
  PrimaryContactTwitterPurpose = 'PrimaryContactTwitterPurpose',
  ProbationPeriod = 'ProbationPeriod',
  LastNamePrefix = 'LastNamePrefix',
  LastDateWorked = 'LastDateWorked',
  BirthDate = 'BirthDate',
  PrimaryContactFacebookDescription = 'PrimaryContactFacebookDescription',
  WorksFromHome = 'WorksFromHome',
  SummaryValidFrom = 'SummaryValidFrom',
  PrimaryContactFaxExtension = 'PrimaryContactFaxExtension',
  AddressCountryRegionISOCode = 'AddressCountryRegionISOCode',
  PartyType = 'PartyType',
  VeteranStatusId = 'VeteranStatusId',
  RegulatoryEstablishmentId = 'RegulatoryEstablishmentId',
  PrimaryContactTwitterDescription = 'PrimaryContactTwitterDescription',
  TerminationDate = 'TerminationDate',
  EmploymentEndDate = 'EmploymentEndDate',
  PersonDetailsValidTo = 'PersonDetailsValidTo',
  PrimaryContactLinkedInDescription = 'PrimaryContactLinkedInDescription',
  CitizenshipCountryRegion = 'CitizenshipCountryRegion',
  MiddleName = 'MiddleName',
  PrimaryContactEmailPurpose = 'PrimaryContactEmailPurpose',
  EmploymentNoticeEmployerQuantity = 'EmploymentNoticeEmployerQuantity',
  AddressValidFrom = 'AddressValidFrom',
  PrimaryContactURLPurpose = 'PrimaryContactURLPurpose',
  AddressDistrictName = 'AddressDistrictName',
  IsDisabled = 'IsDisabled',
  Gender = 'Gender',
  AddressLocationRoles = 'AddressLocationRoles',
  ExpatriateRulingValidFrom = 'ExpatriateRulingValidFrom',
  SummaryValidTo = 'SummaryValidTo',
  PartyNumber = 'PartyNumber',
  PrimaryAddressLocation = 'PrimaryAddressLocation',
  AllowRehire = 'AllowRehire',
  Name = 'Name',
  PrimaryContactFaxDescription = 'PrimaryContactFaxDescription',
  PrimaryContactLinkedIn = 'PrimaryContactLinkedIn',
  NumberOfDependents = 'NumberOfDependents',
  PersonalTitle = 'PersonalTitle',
  PrimaryContactFacebook = 'PrimaryContactFacebook',
  NameAlias = 'NameAlias',
  DeceasedDate = 'DeceasedDate',
  PrimaryContactPhone = 'PrimaryContactPhone',
  Education = 'Education',
  SeniorityDate = 'SeniorityDate',
  PrimaryContactEmailDescription = 'PrimaryContactEmailDescription',
  StartDate = 'StartDate',
  MotherBirthCountryRegion = 'MotherBirthCountryRegion',
  OfficeLocation = 'OfficeLocation',
  EmploymentNoticeEmployerUnit = 'EmploymentNoticeEmployerUnit',
  PrimaryContactEmail = 'PrimaryContactEmail',
  PhoneticMiddleName = 'PhoneticMiddleName',
  IsExpatriateRulingApplicable = 'IsExpatriateRulingApplicable',
  PhoneticLastName = 'PhoneticLastName',
  PrimaryContactTwitter = 'PrimaryContactTwitter',
  EmploymentDetailsExpiration = 'EmploymentDetailsExpiration',
  PrimaryContactFacebookPurpose = 'PrimaryContactFacebookPurpose',
  AnniversaryDateTime = 'AnniversaryDateTime',
  PrimaryContactPhoneIsPrivate = 'PrimaryContactPhoneIsPrivate',
  FirstName = 'FirstName',
  ProfessionalTitle = 'ProfessionalTitle',
  PrimaryContactLinkedInIsPrivate = 'PrimaryContactLinkedInIsPrivate',
  NativeLanguageId = 'NativeLanguageId',
  PrimaryContactFaxPurpose = 'PrimaryContactFaxPurpose',
  KnownAs = 'KnownAs',
  AdjustedWorkerStartDate = 'AdjustedWorkerStartDate',
  EmploymentNoticeWorkerUnit = 'EmploymentNoticeWorkerUnit',
  DisabledVerificationDate = 'DisabledVerificationDate',
  MaritalStatus = 'MaritalStatus',
  EmploymentDetailsEffective = 'EmploymentDetailsEffective',
  IsDisabledVeteran = 'IsDisabledVeteran',
  EthnicOriginId = 'EthnicOriginId',
  PhoneticFirstName = 'PhoneticFirstName',
  ElectronicLocationId = 'ElectronicLocationId',
  PensionStart = 'PensionStart',
  PensionEnrollment = 'PensionEnrollment',
  CalendarDataAreaId = 'CalendarDataAreaId',
  CalendarId = 'CalendarId',
  SPLExternalPersonnelNumber = 'SPLExternalPersonnelNumber',
  SPLRecId = 'SPLRecId',
  SPLRecVersion = 'SPLRecVersion',
  SPLEmployeeType = 'SPLEmployeeType',
  SPLModifiedDateTime = 'SPLModifiedDateTime',
  SPLCreatedDateTime = 'SPLCreatedDateTime',
}
export const requestableFoCustomerV2DtoFields = [
  FoEmployeesV2DtoFields.PrimaryContactEmail,
  FoEmployeesV2DtoFields.EmploymentLegalEntityId,
  FoEmployeesV2DtoFields.PersonnelNumber,
  FoEmployeesV2DtoFields.SPLExternalPersonnelNumber,
  FoEmployeesV2DtoFields.SPLEmployeeType,
  FoEmployeesV2DtoFields.SPLRecId,
  FoEmployeesV2DtoFields.PartyNumber,
  FoEmployeesV2DtoFields.PartyType,
  FoEmployeesV2DtoFields.LanguageId
];
export interface ResquestableFoEmployeesV2Dto {
  PrimaryContactEmail: string
  EmploymentLegalEntityId: string
  PersonnelNumber: string
  SPLExternalPersonnelNumber: string
  SPLEmployeeType: string
  SPLRecId: number
  PartyNumber: string
  PartyType: string
  LanguageId: string
}
