import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly THEME_KEY = 'isDarkTheme';
  private readonly isDarkTheme = new BehaviorSubject<boolean>(this.loadTheme());
  isDarkTheme$ = this.isDarkTheme.asObservable();

  constructor() {
    this.applyTheme(this.isDarkTheme.value);
  }

  toggleTheme(isDark: boolean): void {
    this.isDarkTheme.next(isDark);
    this.saveTheme(isDark);
    this.applyTheme(isDark);
  }

  private saveTheme(isDark: boolean): void {
    localStorage.setItem(this.THEME_KEY, JSON.stringify(isDark));
  }

  private loadTheme(): boolean {
    const savedTheme = localStorage.getItem(this.THEME_KEY);
    return savedTheme ? JSON.parse(savedTheme) : false;
  }

  private applyTheme(isDark: boolean): void {
    if (isDark) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }
}
